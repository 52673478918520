<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
           <h5 class="mb-0">{{ Action === 'Edit' ? $t('Navigation.Products/EditCategory') : $t('Navigation.Products/AddCategory') }}</h5>
            <div class="card-header-actions d-flex">
              <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
              <CButton color="success" type="submit">
                {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTabs fade addTabsWrapperClasses="tab-info">
              <CTab :title="$t('Product/Category.Info')" active>
                <CRow>
                  <CCol md="8">
                    <CInput
                      :label="$t('Product/Category.Slug')"
                      v-model="Data.Slug"
                      horizontal
                    />
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Post/Category.Name')"
                        v-model="Data.Name"
                        horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Post/Category.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            :key="NowChooseLanguage"
                            v-model="Data.Tags"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.Tags"
                            :multiple="true"
                            :taggable="true"
                            @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CTextarea :label="$t('Post/Detail.Introduction')" v-model="Data.Introduction" rows="4" horizontal />
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Post/Category.Name')"
                        v-model="Data.LanguageData[NowChooseLanguage].Name"
                        horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Post/Category.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            :key="NowChooseLanguage"
                            v-model="Data.LanguageData[NowChooseLanguage].Tags"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.LanguageData[NowChooseLanguage].Tags"
                            :multiple="true"
                            :taggable="true"
                            @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CTextarea :label="$t('Post/Detail.Introduction')" v-model="Data.LanguageData[NowChooseLanguage].Introduction" rows="4" horizontal />
                    </template>
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Category.RelateCategories') }}
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                          v-model="SelectCategories"
                          :options="CategoryList"
                          :optionHeight="34"
                          label="label"
                          track-by="value"
                          :placeholder="$t('Global.PleaseSelect')"
                          :selectLabel="$t('Global.SelectLabel')"
                          :selectedLabel="$t('Global.SelectedLabel')"
                          :deselectLabel="$t('Global.DeselectLabel')"
                          :multiple="true"
                          @input="SynchronizeCategories()"
                        >
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>
                    <CSelect horizontal description="若未設定商品排序方式則預設排序為「自訂排序遞減」" :label="$t('Product/Category.ProductSort')" :options="SortOption" v-model="Data.Variable.ProductSort" :value.sync="Data.Variable.ProductSort" :placeholder="$t('Global.PleaseSelect')"/>
                  </CCol>
                  <CCol md="4">
                    <CInput
                      :label="$t('Product/Category.Sort')"
                      v-model="Data.Sort"
                      horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Category.Status') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="CategoryStatus"/>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="Data.Variable.ProductSort === 'custom_sort'" :title="$t('Product/List.CustomDragSort')">
                <CAlert color="info">
                  拖曳單列可交換商品排序。
                </CAlert>
<!--                <pre>{{ Data.Variable.ProductList }}</pre>-->
                <div class="position-relative table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th v-for="Field in ProductField" scope="col" :key="Field.key">
                        {{ Field.label }}
                      </th>
                    </tr>
                    </thead>
                    <draggable v-model="Data.Variable.ProductList" tag="tbody">
                      <tr v-for="Item in Data.Variable.ProductList" :key="Item._id">
                        <td v-for="Field in ProductField" :key="Field.key">
                          <template v-if="Field.key === 'Cover'">
                            <img v-lazy="Item[Field.key]" width="40" height="40" class="img-fluid" />
                          </template>
                          <template v-else>
                            {{ Item[Field.key] }}
                          </template>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </CTab>
              <CTab :title="$t('Product/Category.Images')">
                <h5>
                  {{ $t('Post/Detail.Carousel') }}
                </h5>
                <hr>
                <template v-if="NowChooseLanguage === 'default'">
                  <Draggable
                    v-model="Data.Carousel"
                    class="row no-gutters mx-n1 mb-3"
                    tag="div"
                    v-bind="DragOptions"
                    @start="Drag = true"
                    @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                </template>
                <template v-else>
                  <Draggable
                    v-model="Data.LanguageData[NowChooseLanguage].Carousel"
                    class="row no-gutters mx-n1 mb-3"
                    tag="div"
                    v-bind="DragOptions"
                    @start="Drag = true"
                    @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.LanguageData[NowChooseLanguage].Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                </template>
                <h5>{{ $t('Post/Category.Cover') }}</h5>
                <hr>
                <CRow class="no-gutters mx-n1 mb-3">
                  <template v-if="NowChooseLanguage === 'default'">
                    <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </template>
                  <template v-else>
                    <CCol v-if="Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.LanguageData[NowChooseLanguage].Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </template>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Category.Content')">
                <template v-if="NowChooseLanguage === 'default'">
                  <Editor api-key="5gnbjytrb81qsj0gwbts7ccp84waege1b15fr5q7gvvk4w0e" :init="TinyMCE" v-model="Data.Variable.Content" />
                </template>
                <template v-else>
                  <Editor api-key="5gnbjytrb81qsj0gwbts7ccp84waege1b15fr5q7gvvk4w0e" :init="TinyMCE" v-model="Data.LanguageData[NowChooseLanguage]['Variable.Content']" />
                </template>
              </CTab>
              <CTab :title="$t('Product/Category.SEO')">
                <CRow>
                  <CCol md="8">
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Post/Category.SEO/Title')"
                        v-model="Data.SEO.Title"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/Keyword')"
                        v-model="Data.SEO.Keywords"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/Description')"
                        v-model="Data.SEO.Description"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/OG:Title')"
                        v-model="Data.SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/OG:Description')"
                        v-model="Data.SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/OG:Image')"
                        v-model="Data.SEO.OGImage"
                        horizontal
                      />
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Post/Detail.SEO/Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Title"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/Keyword')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Keywords"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Category.SEO/Description')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Description"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Description')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Image')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGImage"
                        horizontal
                      />
                    </template>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.Setting')">
                <template v-if="(Object.keys(VariableExtend).length > 0)">
                  <template v-for="(Config, Variable) in VariableExtend">
                    <h5 :key="Variable+ '-title'">
                      {{ $t('Product/ExtendVariable.' + Variable) }}
                    </h5>
                    <hr :key="Variable + '-hr'">
                    <Editor v-if="Config.FormType === 'editor'" :key="Variable + '-content'" api-key="5gnbjytrb81qsj0gwbts7ccp84waege1b15fr5q7gvvk4w0e" :init="TinyMCE" v-model="Data.Variable[Variable]" />
                    <CInput v-if="Config.FormType === 'text'" type="text" :key="Variable + '-content'"
                            v-model="Data.Variable[Variable]"/>
                    <CTextarea v-if="Config.FormType === 'textarea'" row="3" :key="Variable + '-content'"
                               v-model="Data.Variable[Variable]"/>
                    <template v-if="Config.FormType === 'array'">
                      <div class="mb-3" :key="Variable + '-content'">
                        <CInput v-for="(Data, Index) in Data.Variable[Variable]" type="text"
                                :key="'Array-' + Variable + Index" v-model="Data.Value">
                          <template #append>
                            <CButton color="light" @click="RemoveVariableArrayInput(Variable, Index)">
                              <CIcon class="my-0" name="cil-trash" size="sm"/>
                            </CButton>
                          </template>
                        </CInput>
                        <CButton :key="Variable + '-btn'" color="info" size="sm" type="button"
                                 @click="AddVariableArrayInput(Variable)">
                          <CIcon name="cil-plus" class="mr-1"/>
                          {{ $t('Global.Add') }}
                        </CButton>
                      </div>
                    </template>
                    <template v-if="Config.FormType === 'gallery'">
                      <Draggable
                        v-model="Data.Variable[Variable]"
                        class="row no-gutters mx-n1 mb-3"
                        tag="div"
                        :key="Variable + '-content'"
                        v-bind="DragOptions"
                        @start="Drag = true"
                        @end="Drag = false"
                      >
                        <CCol v-for="(Data, Index) in Data.Variable[Variable]" :key="Index" xl="2" lg="3" md="4" sm="6"
                              class="px-1 mb-2">
                          <div class="position-relative CarouselItem">
                            <img v-lazy="Data.URL" class="img-fluid"/>
                            <div class="CarouselTools">
                              <CButton color="info" size="sm" class="mx-1"
                                       @click="OpenMediaStore(Variable, false, Index, true)">
                                <CIcon name="cil-pencil"/>
                              </CButton>
                              <CButton color="secondary" size="sm" class="mx-1"
                                       @click="OpenMediaMeta(Variable, Index, true)">
                                <CIcon name="cil-pencil"/>
                              </CButton>
                              <CButton color="danger" size="sm" class="mx-1"
                                       @click="RemoveImage(Variable, Index, true)">
                                <CIcon name="cil-trash"/>
                              </CButton>
                            </div>
                          </div>
                        </CCol>
                        <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                          <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                            <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages"/>
                            <div class="CarouselTools">
                              <CButton color="success" size="sm" class="mx-1"
                                       @click="OpenMediaStore(Variable, true, false, true)">
                                <CIcon name="cil-image-plus"/>
                                {{ $t('Global.Add') }}
                              </CButton>
                            </div>
                          </div>
                        </CCol>
                      </Draggable>
                    </template>
                  </template>
                </template>
                <CAlert v-else color="warning">{{ $t('Product/Detail.NoSetting') }}</CAlert>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta"></MediaMeta>
    <input id="tinymce-image-input" type="text" class="d-none">
  </section>
</template>

<route>
{
"meta": {
"label": "編輯商品分類"
}
}
</route>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'ProductCategoryDetail',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    MediaStore: () => import('@/components/public/Media'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Draggable: () => import('vuedraggable'),
    Editor
  },
  data() {
    return {
      Action: 'Edit',
      Data: {
        Name: '',
        Slug: '',
        Tags: [],
        RelateCategories: [],
        Status: 0,
        Sort: 0,
        EnableResponseContent: 0,
        SEO: {
          Description: '',
          Keywords: ''
        },
        Carousel: [],
        Variable: {
          _v: 0,
          ProductList: [],
        },
        LanguageData: {},
        ResponseData: {
          Mobile: {},
          Web: {}
        }
      }, //主資料

      OldSlug: '',
      CategoryList: [],
      SelectCategories: [], //格式化後的已選商品分類
      CategoryStatus: false, //格式化後的響應式商品內容開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      VariableList: [],

      NowChooseDevice: 'Web',
      NowChooseLanguage: 'default',
      Loading: false,
      Drag: false,
      ChooseImageType: '',
      IsVariable: false,
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },
      VariableExtend: {},
      VariableExtendDefault: {
        array: [],
        gallery: [],
        editor: '',
        text: '',
        textarea: ''
      },
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },
      TypeList: ['boolean', 'string', 'number', 'object', 'array'],
      VariableNoItems: {
        noResults: this.$t('Product/Detail.NoVariableItems'),
        noItems: this.$t('Product/Detail.NoVariableItems')
      }
    }
  },
  computed: {
    VariableField() {
      return [
        {key: 'Index', label: this.$t('Product/Detail.Index')},
        {key: 'Value', label: this.$t('Product/Detail.Value')},
        {key: 'Type', label: this.$t('Product/Detail.Type')},
        {key: 'Action', label: ''}
      ]
    },
    SortOption() {
      return [
        {value: 'custom_sort', label: this.$t('Product/List.CustomDragSort')},
        {value: 'create_otn', label: this.$t('Product/List.SortType.create_otn')},
        {value: 'create_nto', label: this.$t('Product/List.SortType.create_nto')},
        {value: 'otn', label: this.$t('Product/List.SortType.otn')},
        {value: 'nto', label: this.$t('Product/List.SortType.nto')},
        {value: 'lth', label: this.$t('Product/List.SortType.lth')},
        {value: 'htl', label: this.$t('Product/List.SortType.htl')},
        {value: 'sort_asc', label: this.$t('Product/List.SortType.sort_asc')},
        {value: 'sort_desc', label: this.$t('Product/List.SortType.sort_desc')},
        {value: 'customSeriesNum_asc', label: this.$t('Product/List.SortType.customSeriesNum_asc')},
        {value: 'customSeriesNum_desc', label: this.$t('Product/List.SortType.customSeriesNum_desc')},
        {value: 'sku_asc', label: this.$t('Product/List.SortType.sku_asc')},
        {value: 'sku_desc', label: this.$t('Product/List.SortType.sku_desc')},
        {value: 'seriesNum_asc', label: this.$t('Product/List.SortType.seriesNum_asc')},
        {value: 'seriesNum_desc', label: this.$t('Product/List.SortType.seriesNum_desc')}
      ]
    },
    ProductField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'CustomSeriesNum', label: this.$t('Product/List.CustomSeriesNum') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') }
      ]
    },
    DragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  created() {
    this.Action = (this.$route.name === 'products-category-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Functions = [this.GetCategoryExtendData(), this.GetCategories()]
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        this.HandleCategoryExtendData()
        this.GetList()
        //多語系資料處理
        if (this.$store.state.user.permission.MutipleLocale) {
          if (!this.Data.LanguageData) {
            this.$set(this.Data, 'LanguageData', {})
          }
          this.$store.state.user.permission.Locales.forEach((locale) => {
            if (!this.Data.LanguageData[locale]) {
              this.$set(this.Data.LanguageData, locale, {
                Name: '',
                Tags: [],
                SEO: {
                  Description: '',
                  Keywords: ''
                },
                Cover: '',
                Carousel: [],
                'Variable.Content': '',
                ResponseData: {
                  Mobile: {},
                  Web: {}
                }
              })
            }
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.MutipleLocale ? this.$store.state.user.permission.DefaultLocale : 'default'
        this.SelectCategories = this.CategoryList.filter((item) => {
          return this.Data.RelateCategories.includes(item.value)
        })
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/list',
        method: 'get',
      }).then(({data}) => {
        this.CategoryList = data.list.filter(item => item.Slug !== this.$route.params.slug).map((category) => {
          return { label: category.Name, value: category.Slug }
        })
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/product/availableList',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
          Taxonomy: 'Default',
          Order: 'sort_desc',
          Categories: [this.Data.Slug],
        }
      }).then(({data}) => {
        if (!Array.isArray(this.Data.Variable.ProductList)) {
          const ProductList = data.Data.map(item => {
            return {
              Name: item.Name,
              Cover: item.Cover,
              _id: item._id,
              SeriesNum: item.SeriesNum,
              CustomSeriesNum: item.CustomSeriesNum
            }
          })
          this.$set(this.Data.Variable, 'ProductList', ProductList)
        } else {
          const ProductSeriesNums = this.Data.Variable.ProductList.map(item => item.SeriesNum)
          data.Data.forEach((item) => {
            if (ProductSeriesNums.includes(item.SeriesNum)) {
              const CurrentIndex = this.Data.Variable.ProductList.findIndex(product => product.SeriesNum === item.SeriesNum)
              this.Data.Variable.ProductList[CurrentIndex] = {
                ...this.Data.Variable.ProductList[CurrentIndex],
                Name: item.Name,
                Cover: item.Cover,
                _id: item._id,
                SeriesNum: item.SeriesNum,
                CustomSeriesNum: item.CustomSeriesNum
              }
            } else {
              this.Data.Variable.ProductList.push({
                Name: item.Name,
                Cover: item.Cover,
                _id: item._id,
                SeriesNum: item.SeriesNum,
                CustomSeriesNum: item.CustomSeriesNum
              })
            }
          })
        }
      }).catch((err) => {
        throw err
      })
    },
    Save() {
      let Action
      this.Data.Slug = this.Data.Slug.replace(/\//g, '_')
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Product/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/products/category/detail/' + result.data.Slug)
        }
        if (this.Action === 'Edit' && this.OldSlug !== this.Data.Slug) {
          this.$router.replace('/products/category/detail/' + this.Data.Slug)
        }
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code + ']',
          text: this.$t('Message.Product/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Product/SlugEmpty')
        }
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/add',
        method: 'post',
        data: {
          ...this.Data,
          Status: this.CategoryStatus ? 1 : 0,
          EnableResponseContent: this.EnableResponseContent ? 1 : 0
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.ProductCategory/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Product/SlugEmpty')
        }
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      const updateData = {
        ...this.Data,
        Status: this.CategoryStatus ? 1 : 0,
        EnableResponseContent: this.EnableResponseContent ? 1 : 0
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/edit',
        method: 'post',
        data: {
          slug: this.OldSlug,
          updateData
        }
      }).then(() => {
        return {
          msg: this.$t('Message.ProductCategory/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/detail',
        method: 'get',
        params: {
          slug: this.$route.params.slug
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
        this.OldSlug = this.Data.Slug
        this.CategoryStatus = (data.detail.Status === 1)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetCategoryExtendData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/extend',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.VariableExtend = data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    HandleCategoryExtendData() {
      Object.keys(this.VariableExtend).forEach((Variable) => {
        if (!this.Data.Variable) this.$set(this.Data, 'Variable', {})
        if (!this.Data.Variable[Variable]) this.$set(this.Data.Variable, Variable, this.VariableExtendDefault[this.VariableExtend[Variable].FormType])
      })
    },
    AddTag (newTag) {
      this.Data.Tags.push(newTag)
      if (this.NowChooseLanguage !== 'default') {
        this.Data.LanguageData[this.NowChooseLanguage].Tags.push(newTag)
      }
    },
    AddVariable() {
      this.VariableList.push({
        Index: '',
        Value: '',
        Type: 'string'
      })
    },
    RemoveVariable(_Index) {
      this.VariableList.splice(_Index, 1)
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false, isVariable = false) {
      this.ChooseImageType = type
      this.IsVariable = isVariable
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages(type = 'Carousel') {
      const storageInstance = this.$store.state.userProjectApp.storage()
      switch (type) {
        case 'Cover':
          this.SetSingleImage('Cover')
          break
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'));
          break
        default:
          if (typeof this.Data[type] === 'undefined') {
            this.$set(this.Data, type, [])
          }
          this.ChooseImageType = ''
          switch (typeof this.MediaStoreConfig.ChooseImageList) {
            //圖片單選
            case 'string':
              if (this.$store.state.user.permission.StaticDomain) {
                if (this.IsVariable) {
                  this.$set(this.Data['Variable'][type], this.ChooseImageIndex, {
                    URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                    Alt: ''
                  })
                } else if (this.NowChooseLanguage === 'default') {
                  this.$set(this.Data[type], this.ChooseImageIndex, {
                    URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                    Alt: ''
                  })
                  return true
                }
                this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {
                  URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                  Alt: ''
                })
              } else {
                storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
                  if (this.IsVariable) {
                    this.$set(this.Data['Variable'][type], this.ChooseImageIndex, {URL, Alt: ''})
                  } else if (this.NowChooseLanguage === 'default') {
                    this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
                    return true
                  }
                  this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {
                    URL,
                    Alt: ''
                  })
                })
              }
              break
            //圖片多選
            case 'object':
              if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
                this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
                  if (this.$store.state.user.permission.StaticDomain) {
                    if (this.IsVariable) {
                      this.Data['Variable'][type].push({
                        URL: this.$store.state.user.permission.StaticDomain + imgPath,
                        Alt: ''
                      })
                    } else if (this.NowChooseLanguage === 'default') {
                      this.Data[type].push({
                        URL: this.$store.state.user.permission.StaticDomain + imgPath,
                        Alt: ''
                      })
                      return true
                    }
                    this.Data.LanguageData[this.NowChooseLanguage][type].push({
                      URL: this.$store.state.user.permission.StaticDomain + imgPath,
                      Alt: ''
                    })
                  } else {
                    storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                      if (this.IsVariable) {
                        this.Data['Variable'][type].push({
                          URL,
                          Alt: ''
                        })
                      } else if (this.NowChooseLanguage === 'default') {
                        this.Data[type].push({
                          URL,
                          Alt: ''
                        })
                        return true
                      }
                      this.Data.LanguageData[this.NowChooseLanguage][type].push({
                        URL,
                        Alt: ''
                      })
                    })
                  }
                })
              }
              break
          }
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (this.NowChooseLanguage === 'default') {
          if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        } else {
          if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        }
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (this.NowChooseLanguage === 'default') {
            if (index) this.Data[type][index] = URL
            else this.$set(this.Data, type, URL)
          } else {
            if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = URL
            else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, URL)
          }
        })
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false, isVariable = false) {
      if (index === false) {
        return false
      }
      if (isVariable) {
        this.MediaMetaConfig.ImageMeta = this.Data['Variable'][type][index]
      } else {
        this.MediaMetaConfig.ImageMeta = this.Data[type][index]
      }
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.ChooseImageType = type
      this.IsVariable = isVariable
      this.ChooseImageIndex = index
    },
    SetMeta() {
      this.MediaMetaConfig.ImageMeta = {}
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.IsVariable = false
      this.MediaMetaConfig.Display = false
    },
    RemoveImage(type = '', index, isVariable = false) {
      if (isVariable) {
        this.Data['Variable'][type].splice(index, 1)
      } else {
        this.Data[type].splice(index, 1)
      }
    },
    SynchronizeCategories() {
      this.Data.RelateCategories = this.SelectCategories.map(data => {
        return data.value
      })
    },
    SynchronizeDefaultLocaleData() {
      const DefaultLocale = this.$store.state.user.permission.DefaultLocale
      if (DefaultLocale !== 'default') {
        this.Data.Name = this.Data.LanguageData[DefaultLocale].Name
        this.Data.SEO = this.Data.LanguageData[DefaultLocale].SEO
        this.Data.Introduction = this.Data.LanguageData[DefaultLocale].Introduction
        this.Data.Cover = this.Data.LanguageData[DefaultLocale].Cover
        this.Data.Carousel = this.Data.LanguageData[DefaultLocale].Carousel
        this.Data.Variable.Content = this.Data.LanguageData[DefaultLocale]['Variable.Content']
      }
    },
  }
}
</script>
